<template lang="html">
  <section class="rsc-page padding-menu wrapper">
    <section class="breadcrumb">
      <div class="container row-end">
        <router-link to="/">Grupo IAN</router-link>
        <router-link to="/" style="pointer-events: none;">{{
          $t("navbar_menu.commitment.title")
        }}</router-link>
        <router-link :to="$route.path">{{
          $t("commitment.title")
        }}</router-link>
      </div>
    </section>

    <section class="intro-page">
      <div class="container">
        <h1 class="waypoints">{{ $t("commitment.title") }}</h1>
      </div>
      <div class="container-video">
        <div class="container">
          <video controls autoplay playsinline loop preload="metadata" muted ref="video">
            <source :src="'/img/video-intro-' + $i18n.locale + '.mp4'" type="video/mp4" />
          </video>
        </div>
      </div>
      <img :src="$t('commitment.main_banner.btn_img')" class="down-hoja" alt="" @click="scrollSection('objectives')" />
    </section>
    <section class="info-rsc" id="objectives">
      <div class="container waypoints">
        <div class="text" v-html="$t('commitment.objectives_banner.text')"></div>
        <img :src="$t('commitment.objectives_banner.img')" alt="" />
      </div>
    </section>

    <section class="link-comprometidos hidde-mobile">
      <div class="container waypoints">
        <div class="item" v-for="(option, key) in $t(
          'commitment.option_commitments_banner.options'
        )" :key="key">
          <img :src="option.img" :alt="option.alt" />
          <button class="link-arrow-down-green" @click="scrollSection(option.id)">
            {{ $t("commitment.option_commitments_banner.btn_text") }}
          </button>
        </div>
      </div>
      <img :src="$t('commitment.option_commitments_banner.img')" alt="" class="decorado-bottom" />
    </section>
    <section class="link-comprometidos hidde-destop">
      <div class="container waypoints">
        <div class="item" v-for="(option, key) in $t(
          'commitment.option_commitments_banner.options'
        )" :key="key">
          <img :src="option.imgMobile" :alt="option.alt" />
          <button class="link-arrow-down-green" @click="scrollSection(option.id)">
            {{ $t("commitment.option_commitments_banner.btn_text") }}
          </button>
        </div>
      </div>
      <img :src="$t('commitment.option_commitments_banner.img')" alt="" class="decorado-bottom" />
    </section>

    <template v-for="(commitment, key) in $t('commitment.commitments')">
      <section class="banner-comprometidos" :id="commitment.id" :key="commitment.id">
        <div class="container waypoints" effect="animate__fadeIn">
          <img :src="commitment.img" :alt="commitment.alt" />
          <div class="cont-text">
            <div v-html="commitment.title"></div>
            <div class="box-icons">
              <img v-for="(icon, idx) in commitment.icons" :src="icon.img" :key="idx" />
            </div>
            <div v-html="commitment.text"></div>
          </div>
        </div>
      </section>
      <section :class="commitment.objectives.length > 3
        ? '-four cajas-hover waypoints'
        : 'cajas-hover waypoints'
        " effect="animate__fadeIn" :key="key">
        <template v-for="(objective, i) in commitment.objectives">
          <div class="grid" :key="objective.id">
            <img :src="objective.img" :alt="objective.title" />
            <p class="title" v-html="objective.title"></p>
            <div class="mask-hover">
              <p class="title-inter" v-html="objective.title"></p>
              <div v-html="objective.text"></div>
              <div v-if="objective.knowMore" v-html="objective.knowMore" @click="showBanner(commitment.id, objective.id)">
              </div>
            </div>
          </div>
          <div class="banner-consult-code-ethics mobile" :key="i"
            v-if="commitment.id === 'good-practice' && Object.entries(showObjective).length > 0 && show && objective.id === showObjective.id">
            <div class="container">
              <img class="img-lg" :src="showObjective.banner_consult_code_ethics.img"
                :alt="showObjective.banner_consult_code_ethics.alt">
              <div class="cont-text">
                <span class="close" @click="show = !show">X</span>
                <div v-for="(option, idx) in showObjective.banner_consult_code_ethics.options" :key="idx">
                  <a :href="option.link" target="_blank">
                    {{ option.text }}
                    <img src="/img/flecha-blanca.svg">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </template>
      </section>
      <section v-if="commitment.id === 'good-practice' && Object.entries(showObjective).length > 0 && show"
        class="banner-consult-code-ethics desktop" :key="key + 1">
        <div class="container">
          <img :src="showObjective.banner_consult_code_ethics.img" :alt="showObjective.banner_consult_code_ethics.alt">
          <div class="cont-text">
            <span class="close" @click="show = !show">X</span>
            <div v-for="(option, idx) in showObjective.banner_consult_code_ethics.options" :key="idx">
              <a :href="option.link" target="_blank">
                {{ option.text }}
                <img src="/img/flecha-blanca.svg">
              </a>
            </div>
          </div>
        </div>
      </section>
    </template>

    <section class="comprometidos-banner-logo">
      <img :src="$t('commitment.commitment_banner.img')" :alt="$t('commitment.commitment_banner.alt')"
        class="waypoints" />
      <img :src="$t('commitment.commitment_banner.img_bottom')" alt="" class="decorado-bottom" />
    </section>

    <section class="banner-proyectos-rsc waypoints">
      <div class="container">
        <h2 class="main-title waypoints">
          {{ $t("commitment.rsc_projects_banner.title") }}
        </h2>
        <div v-html="$t('commitment.rsc_projects_banner.text')"></div>
        <router-link class="link-arrow waypoints" :to="$t('paths.proyectos-rsc')"></router-link>
        <h3 class="main-title waypoints">
          {{ $t("commitment.rsc_projects_banner.subtitle") }}
        </h3>
        <a :href="$t('commitment.rsc_projects_banner.download_pdf')" download class="btn-descargar">
          {{ $t("commitment.rsc_projects_banner.download") }}
        </a>
      </div>
    </section>
  </section>
</template>

<script lang="js">

export default {
  name: 'compromiso-ian',
  data: () => ({
    show: false,
    showObjective: {}
  }),
  methods: {
    scrollSection: function (params) {
      setTimeout(function () {
        document.getElementById(params).scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      }, 200);

    },
    showBanner(commitmentId, objetiveId) {
      if (this.showObjective.id === objetiveId) {
        this.show = !this.show;
      } else {
        let commitment = this.$t(`commitment.commitments`).find((commitment) => commitment.id === commitmentId)
        this.showObjective = commitment.objectives.find((objetive) => objetive.id === objetiveId)
        this.show = !this.show;
      }
    }
  },
  watch: {
    '$route'() {
      window.location.reload();

    }
  }
}
</script>

<style scoped lang="scss">
.rsc {}
</style>
